<template>
    <section class="tables">
        <vue-snotify></vue-snotify>
        <form class="forms-sample" @submit.stop.prevent="onSubmit" autocomplete="off">
            <div class="row new-updated-design">
                <div class="col-md-6 d-flex align-items-stretch grid-margin">
                    <div class="row flex-grow">
                        <div class="col-12 grid-margin">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title" v-if="form.id == ''">Add New User</h4>
                                    <h4 class="card-title" v-else>Update Role</h4>
                                </div>
                                <div class="card-body">
                                    <!--                                <form class="forms-sample" @submit.stop.prevent="onSubmit" autocomplete="off">-->
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <b-form-group label="Client*" label-for="Client">
                                                <b-form-select :options="clients"
                                                               class="cst-new-select"
                                                               v-model="$v.form.client_id.$model"
                                                               :state="$v.form.client_id.$dirty ? !$v.form.client_id.$error : null"
                                                               aria-describedby="input-2-live-feedback"
                                                ></b-form-select>
                                                <b-form-invalid-feedback id="input-2-live-feedback">This is a required
                                                    field.
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                            <b-form-group label="Name*" label-for="name">
                                                <b-form-input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        autocomplete="new-password"
                                                        placeholder="Name"
                                                        v-model="$v.form.name.$model"
                                                        :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                                                        aria-describedby="input-1-live-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback id="input-1-live-feedback">This is a required
                                                    field.
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <b-form-group label="Email*" label-for="email">
                                                <b-form-input
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        autocomplete="new-password"
                                                        placeholder="Email"
                                                        v-model="$v.form.email.$model"
                                                        :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
                                                        aria-describedby="input-3-live-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.form.email.required"
                                                                         id="input-3-live-feedback">This is a required
                                                    field.
                                                </b-form-invalid-feedback>
                                                <b-form-invalid-feedback v-if="form.email && !$v.form.email.email"
                                                                         id="input-3-live-feedback">Invalid Email.
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                            <b-form-group label="Password" label-for="password">
                                                <b-form-input
                                                        type="password"
                                                        id="password"
                                                        name="password"
                                                        autocomplete="new-password"
                                                        placeholder="Password"
                                                        v-model="$v.form.password.$model"
                                                        :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
                                                        aria-describedby="input-4-live-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.form.password.required"
                                                                         id="input-4-live-feedback">This is a required
                                                    field.
                                                </b-form-invalid-feedback>
                                                <b-form-invalid-feedback v-if="!$v.form.password.minLength"
                                                                         id="input-4-live-feedback">Password must have
                                                    at least
                                                    {{ $v.form.password.$params.minLength.min }} letters.
                                                </b-form-invalid-feedback>
                                                <b-form-invalid-feedback v-if="form.password && !$v.form.password.valid"
                                                                         id="input-4-live-feedback">Password contains
                                                    atleast
                                                    One Uppercase, One Lowercase, One Number and One Special Character.
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-lg-12">
                                            <b-form-group label="Role*" label-for="Role">
                                                <b-form-select :options="roles"
                                                               class="cst-new-select"
                                                               v-model="$v.form.role_name.$model"
                                                               :state="$v.form.role_name.$dirty ? !$v.form.role_name.$error : null"
                                                               aria-describedby="input-4-live-feedback"
                                                ></b-form-select>
                                                <b-form-invalid-feedback id="input-4-live-feedback">This is a required
                                                    field.
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <b-button type="submit" variant="success" :disabled="isDisabled" class="mr-2">
                                        <span v-if="form.id == ''">Submit</span>
                                        <span v-else>Update</span>
                                    </b-button>
                                    <!--                                </form>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-stretch grid-margin">
                    <div class="row flex-grow">
                        <div class="col-12 grid-margin">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">User Login</h4>
                                </div>
                                <div class="card-body">
                                    <b-form-group label="Title" label-for="title">
                                        <b-form-input
                                                type="text"
                                                id="title"
                                                name="title"
                                                autocomplete="new-password"
                                                placeholder="Title"
                                                v-model="form.title"
                                        >
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Mobile Phone" label-for="mobile_phone">
                                        <b-form-input
                                                type="text"
                                                id="mobile_phone"
                                                name="mobile_phone"
                                                autocomplete="new-password"
                                                placeholder="Mobile Phone"
                                                v-model="form.mobile_phone"
                                        >
                                        </b-form-input>
                                    </b-form-group>
                                    <b-button type="button" @click="loginAs" v-if="form.id" variant="success"
                                              class="mr-2">
                                        Login As
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="row new-updated-design">

            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Users</h4>
                    </div>
                    <div class="card-body">
                        <vue-good-table
                                mode="remote"
                                @on-page-change="onPageChange"
                                @on-sort-change="onSortChange"
                                @on-column-filter="onColumnFilter"
                                @on-per-page-change="onPerPageChange"
                                :totalRows="totalRecords"
                                :isLoading.sync="isLoading"
                                @on-search="searchFn"
                                :search-options="{enabled: true,searchFn: searchFn}"
                                :pagination-options="{
    enabled: true,
      dropdownAllowAll: false,
    perPageDropdown: [10, 20, 50, 100, 200],
  }"
                                :rows="rows"
                                :columns="columns">
                            <template slot="table-row" slot-scope="props">
                                                             <span
                                                                     v-if="props.column.field === 'action'"
                                                                     class="text-nowrap"
                                                             >
                                                                 <b-button size="sm" @click="editUser(props.row.id)"
                                                                           class="mr-1 btn-info text-white">
                                                                Edit
                                                            </b-button>
                                                            <b-button size="sm" @click="deleteUser(props.row.id)"
                                                                      class="mr-1 btn-danger text-white">
                                                                Delete
                                                            </b-button>
                                                             </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
        <div></div>
    </section>
</template>

<script>
    import Vue from "vue";
    import SortedTablePlugin from "vue-sorted-table";
    import API from '@/api'
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import {validationMixin} from "vuelidate";
    import {required, minLength, email, requiredIf, sameAs} from "vuelidate/lib/validators";
    import router from "../router";

    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    Vue.use(SortedTablePlugin, {
        ascIcon: '<i class="ti-arrow-down"></i>',
        descIcon: '<i class="ti-arrow-up"></i>'
    });
    export default {
        name: 'Users',
        mixins: [validationMixin],
        data() {
            return {
                form: {
                    name: "",
                    email: "",
                    password: "",
                    id: "",
                    client_id: "",
                    title: "",
                    mobile_phone: "",
                    role_name: ""
                },
                serverParams: {
                    // a map of column filters example: {name: 'john', age: '20'}
                    columnFilters: {},
                    sort: [
                        {
                            field: 'id', // example: 'name'
                            type: 'desc' // 'asc' or 'desc'
                        }
                    ],

                    page: 1, // what page I want to show
                    perPage: 10 // how many items I'm showing per page
                },
                columns: [
                    {
                        label: 'Name',
                        field: 'name',
                        filterable: true
                    },
                    {
                        label: 'Role',
                        field: 'roles',
                        filterable: true,
                        sortable: false,
                        formatFn: this.formatRole
                    },
                    {
                        label: 'Email',
                        field: 'email',
                        filterable: true
                    },
                    {
                        label: 'Client',
                        field: 'clients',
                        sortable: false,
                        formatFn: this.formatFn
                    },
                    {
                        label: 'Action',
                        field: "action",
                        sortable: false,
                    },
                ],
                isLoading: false,
                rows: [],
                totalRecords: 0,
                roles: [],
                clients: [],
                isDisabled: false,
            };
        },
        validations: {
            form: {
                id: {},
                name: {
                    required,
                }, client_id: {
                    required,
                }, email: {
                    email,
                    required,
                }, role_name: {
                    required,
                }, password: {
                    required: requiredIf((value) => value.id === ''),
                    minLength: minLength(6),
                    valid: function (value) {
                        const containsUppercase = /[A-Z]/.test(value)
                        const containsLowercase = /[a-z]/.test(value)
                        const containsNumber = /[0-9]/.test(value)
                        const containsSpecial = /[#?!@$%^&*-]/.test(value)
                        return containsUppercase && containsLowercase && containsNumber && containsSpecial
                    },
                }

            }
        },
        methods: {
            getUserRoles() {
                API.getUserRoles(
                    data => {
                        this.roles = data;
                        // this.form.role_name = 'Admin';
                        let one = 1;
                        for (var i in this.roles) {
                            if (one == 1) {
                                ++one;
                                this.form.role_name = i;
                                this.$v.form.$reset();
                            }
                        }
                    },
                    err => {
                    }
                )
            },

            getAllUsers() {
                API.getAllUser(
                    data => {
                        this.users = data
                    },
                    err => {
                    }
                )
            },


            getAllClients() {
                API.get_all_cleints('',
                    data => {
                        this.clients = data
                        // this.form.client_id =0;
                        let one = 1;
                        for (var i in this.clients) {
                            if (one == 1) {
                                ++one;
                                this.form.client_id = i;
                            }
                        }
                        this.$v.form.$reset();
                    },
                    err => {
                    }
                )
            },
            deleteUser(e) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        let data = {
                            id: e
                        }
                        API.deleteUser(
                            data,
                            data => {
                                this.loadItems();
                                this.$swal(
                                    'Deleted!',
                                    'Record has been deleted.',
                                    'success'
                                )
                            },
                            err => {
                            })
                    }
                })
            },
            editUser(e) {
                window.scrollTo({top: 0, behavior: 'smooth'});

                this.$v.form.$reset();
                API.get_edit_user(
                    e, data => {
                        this.form.name = data.name;
                        this.form.id = data.id;
                        this.form.email = data.email;
                        this.form.mobile_phone = data.mobile_phone;
                        this.form.title = data.title;
                        if (data.roles.length != 0) {
                            this.form.role_name = data.roles[0].name;
                        } else {
                            this.form.role_name = '';
                        }

                        this.form.client_id = data.client_id;
                    },
                    err => {
                    }
                )
            },
            onSubmit() {
                this.$v.form.$touch();

                if (this.$v.form.$anyError) {
                    if (!this.$v.form.password.valid && this.$v.form.password != '' && this.$v.form.password !== '') {
                        this.isDisabled = true;
                        this.userForm();
                    } else {
                        return;
                    }
                }
                if (this.form.name != '' && this.form.email != '' && this.form.role_name != '' && this.form.client_id != '') {
                    this.isDisabled = true;
                    this.userForm();
                } else {
                    this.isDisabled = false;
                    this.$snotify.warning('Please fill required fields', {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });

                }
            }, resetForm() {
                var self = this; //you need this because *this* will refer to Object.keys below`

                //Iterate through each object field, key is name of the object field`
                Object.keys(this.data.form).forEach(function (key, index) {
                    self.data.form[key] = '';
                });
            }, userForm() {
                API.add_edit_users(
                    this.form,
                    data => {
                        this.isDisabled = false;
                        this.$snotify.success(data, {
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });

                        this.form.name = '';
                        this.form.email = '';
                        this.form.password = '';
                        this.form.id = '';
                        this.form.mobile_phone = '';
                        this.form.title = '';
                        this.getAllClients();
                        this.loadItems();
                        this.$v.form.$reset();
                        this.getUserRoles();
                    },
                    err => {
                        this.isDisabled = false;
                        console.log(err['data']['errors']['email'][0]);
                        this.$snotify.error(err['data']['errors']['email'][0], {
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                )
            },


            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                console.log(params.sortType);
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            searchFn(params) {
                this.updateParams({searchTerm: params});
                this.loadItems();
            },
            loadItems() {
                API.getAllUser(this.serverParams,
                    data => {
                        this.totalRecords = data.totalRecords;
                        this.rows = data.data.data;
                        // this.users = data
                    },
                    err => {
                    }
                )
            },
            formatFn: function (value) {
                if (value != null && typeof value['name'] != 'undefined') {
                    return value['name'];
                }
            },
            formatRole: function (value) {
                if (value[0] != null && typeof value[0].name != 'undefined') {
                    return value[0].name;
                }
            },
            loginAs() {
                let data = {
                    email: this.form.email
                }
                var admin_email = JSON.parse(localStorage.getItem('user'));
                API.loginAs(data,
                    data => {
                        if (typeof data.token !== "undefined") {
                            localStorage.setItem('isLoggedin', data.token)
                            localStorage.setItem('client_settings', JSON.stringify(data.client_settings))
                            localStorage.setItem('user', JSON.stringify(data.user))
                            localStorage.setItem('userPermissions', JSON.stringify(data.permission))
                            localStorage.setItem('super_admin_email', admin_email.email)
                            router.push("/dashboard")
                            router.go("/dashboard")
                        }
                    },
                    err => {
                    }
                )
            }

        }, computed: {},
        mounted() {
            this.loadItems();
            this.getUserRoles();
            this.getAllClients();
        }
    }
</script>
